<template>
  <div class="cart-thank-you">
    <div class="icon"></div>
    <h1
      class="headline"
      v-html="$t('terminal.cart.thankyou.headline')"
    ></h1>
    <!-- abort END -->
    <h2
      class="subline"
      v-if="thisQrcode !== ''"
      v-html="$t('terminal.cart.thankyou.receipt')"
    ></h2>

    <!-- index:qr-codes START -->
    <div
      class="qr-codes"
      v-if="thisQrcode !== ''"
    >
      <div class="qr-code download">
        <div class="inner">
          <img :src="`${rootUrl}${thisQrcode}`"/>
        </div>
      </div>
    </div>
    <!-- index:qr-codes END -->

    <div
      class="description"
      v-html="`${$t('terminal.thankyou.window.part.1')} ${count}
         ${$t('terminal.thankyou.window.part.2')}`"
    ></div>
    <div
      class="abort"
    >
      <button
        class="btn-inverted btn-checkout"
        v-on:click="logoutUser()"
        v-on:touchstart="touchStart($event)"
        v-on:touchend="touchEnd($event);"
        v-on:touchcancel="touchEnd($event)"
        v-html="$t('terminal.thankyou.window.abort')"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cart-thank-you',
  data() {
    return {
      /**
       * Settings
       */
      settings: null,
      count: 0,

      thisInvoice: false,
      thisQrcode: '',
      /**
       * Root URL
       */
      rootUrl: localStorage.getItem('anybox_terminal_api_url'),
    };
  },
  async created() {
    // get & set settings
    this.settings = await this.FETCH_SETTINGS();
    if (this.settings) {
      this.count = this.settings.app.timeouts.paymentCountdown / 1000;
    }
    // redirect to screensaver
    this.redirectCountDown();
  },
  computed: {
    orders() {
      if (this.$store.getters.orders) {
        return this.$store.getters.orders;
      }
      return this.$store.getters.orders;
    },
  },
  methods: {
    async FETCH_SETTINGS() {
      await this.$store.dispatch('setSettings');
      return this.$store.getters.settings;
    },

    logoutUser() {
      // clear cart
      if (this.$store.state.storeCart.items) {
        this.$store.dispatch('clearCart');
      }

      const formData = new FormData();
      /**
       * Locale
       */
      formData.append('locale', localStorage.getItem('boxLang'));
      // Logout data
      this.$http.post(`${localStorage.getItem('anybox_terminal_api_url')}/logout`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('anybox_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            // mutating to store for client rendering &
            // saving token in cookie for server rendering
            this.$store.commit('SET_AUTH', null);
            localStorage.removeItem('auth');

            // mutating to store for client rendering &
            // saving user data in cookie for server rendering
            this.$store.commit('SET_USER', null);
            localStorage.removeItem('user');

            // clear other important data
            localStorage.removeItem('qrcodeActive');

            setTimeout(() => {
              this.count = 0;
              // redirect to home
              // this.$router.push('/');
              // redirect to home + reload app
              window.location.assign('/');
            }, 350);
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            console.log(this.errorStatus);
          }
        });
    },

    redirectCountDown() {
      const timeInterval = setInterval(() => {
        this.count -= 1;
        if (this.count <= 0) {
          clearInterval(timeInterval);
          // logout && redirect to screensaver
          this.logoutUser();
        }
      }, 1000);
      // window.location.href = redirect;
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },
  },
  mounted() {
    // animation
    setTimeout(() => {
      if (Boolean(this.$route.query.invoice) === true) {
        this.thisInvoice = true;
      }
      if (this.$route.query.qrcode && this.$route.query.qrcode !== '') {
        this.thisQrcode = this.$route.query.qrcode;
        this.count = this.settings.app.timeouts.paymentCountdown2 / 1000;
      }
      const note = document.querySelector('.note');
      if (note) {
        note.classList.add('loaded');
      }
    }, 350);
  },
};
</script>
